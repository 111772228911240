document.addEventListener("DOMContentLoaded", function() {
    const heroBlock = document.querySelector('section.banner-wrapper');

    if(heroBlock) {
        let slides = heroBlock.querySelectorAll('.media-slide');
        if(slides.length > 0) {
            slides.forEach(function(slide) {
                let videoControls = slide.querySelector('.video-controls');

                if(videoControls) {
                    let playBtn = videoControls.querySelector('button.play-icon');
                    let pauseBtn = videoControls.querySelector('button.pause-icon');
                    playBtn.addEventListener('click', function(e) {
                        e.preventDefault();
                        this.closest('.slider-wrap').querySelectorAll('.pause-icon').forEach(function(icon) {
                            icon.classList.add('show');
                        });
                        this.closest('.slider-wrap').querySelectorAll('.play-icon').forEach(function(icon) {
                            icon.classList.remove('show');
                        });
                        slide.closest('.slider-wrap').querySelectorAll('.media-video video').forEach(function(video) {
                            video.play();
                        });
                    });
                    pauseBtn.addEventListener('click', function(e) {
                        e.preventDefault();
                        this.closest('.slider-wrap').querySelectorAll('.pause-icon').forEach(function(icon) {
                            icon.classList.remove('show');
                        });
                        this.closest('.slider-wrap').querySelectorAll('.play-icon').forEach(function(icon) {
                            icon.classList.add('show');
                        });
                        slide.closest('.slider-wrap').querySelectorAll('.media-video video').forEach(function(video) {
                            video.pause();
                        });
                    });
                }
            });
        }

        $(".section__banner .scrollDownArrowLink").click((e) => {
            e.preventDefault();
            $this = $(e.target);
        
            $("html, body").animate({
                scrollTop: $this.parents(".banner-wrapper").next().offset().top
            }, 500);
        })
    }
});